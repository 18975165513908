/* eslint-disable @typescript-eslint/no-explicit-any */
import { Suspense, lazy, useEffect, useMemo, useRef, useState, useId } from 'react';
import clsx from 'clsx';
import { CardCarouselContainer } from '@marriott/mi-ui-library';
import { useQuery, useLazyQuery } from '@apollo/client';

import { useClientEnvVarsStore } from '@marriott/mi-store-utils';
import { getWCMModeFlagValue, isNonEmptyString } from '@marriott/shared/mi-helper-utils';
import { DEFAULT_ACCEPT_LANGUAGE, useDatalayer, targetPayloadRequest } from '@marriott/mi-headless-utils';

import {
  phoenixOffersCarousel,
  phoenixOffersFallbackOffers,
  phoenixOfferPreview,
  PhoenixDestinationOffersSearchByArea,
  PhoenixDestinationOffersSearchByGeolocation,
} from '@marriott/mi-offers-graphql';

import { getMbopCookie, useGetBreakpoint } from '../../utils/CommonUtils';
import {
  addSubDirectoryPrefix,
  makeDomainSpecificContent,
  checkEliteUser,
  getEdgeHost,
  generateUXLVariablesForDestination,
  processUxlData,
  processOfferCarousel,
  checkNoDataAvailable,
  trackingPropertiesObj,
  objectToArray,
  getTargettingCriteria,
  generateRequestVariables,
} from '../../utils/OfferUtils';
import {
  MBOX_COOKIE,
  CARD_LAYERED_VARIATION,
  NO_OF_CARDS_TABLET,
  OFFER_RECIPE_NONE,
  OFFER_CAROUSEL_URL,
  OFFER_CAROUSEL_URL_REFERRER,
  OFFER_CAROUSEL_CHANNEL,
  OFFER_CAROUSEL_ERROR_POLICY,
  LAYERED_WIDE,
  VARIATION_INVERSE,
  OFFER_RECIPE_PROPERTY_NON_STAY,
  RESORT_OFFERS_RECIPE,
  OFFER_RECIPE_ELITE_EXCLUSIVE,
  CARD_SQUARE,
  DESTINATION_OFFERS_RECIPE,
} from '../../constants/OfferCardConstants';

import { OfferCardsProps } from './OfferCardCarousel.types';
import { StyledOfferCarouselContainer } from './OfferCardCaousel.styles';

export const CardCarousel: React.FC<OfferCardsProps> = props => {
  const { model, acceptLanguage, isAuthorMode, requestId, DestinationDetails } = props;
  const { datalayer } = useDatalayer();
  const {
    latitude = '',
    longitude = '',
    stateCode = '',
    countryCode = '',
    isStateEnabled = false,
    isCountryEnabled = false,
    destinationName = '',
    isPOI = false,
    city = '',
  } = DestinationDetails ?? {};
  const {
    openInaNewTab: openInaNewTabStr = 'false',
    noOfCards,
    ctaLink = '',
    cardsType = CARD_LAYERED_VARIATION,
    variation,
  } = model;
  const isPreview = props?.offersData?.isPreview === 'true' ? true : false;
  const defaultOffersReceipe = model?.offersRecipe;
  const isOfferRecipeNone = defaultOffersReceipe === OFFER_RECIPE_NONE;
  const isRecipePropNonStayOffers = defaultOffersReceipe === OFFER_RECIPE_PROPERTY_NON_STAY;
  const listjson = model?.fallbackOfferIdsList;
  const offerslistjson = listjson?.map((object: { offersId: string }) => object.offersId);
  const [offerResult, setResultOffer] = useState<any>([]);
  const [cardCount, setCardCount] = useState(0);
  const [dataLayerOnLoad, setDataLayerOnLoad] = useState<boolean>(false);
  const [keys, setKeys] = useState<any>([]);
  const [moreCTALink, setMoreCTALink] = useState(ctaLink);
  const [isMobileViewPort, setIsMobileViewPort] = useState(useGetBreakpoint() === 'mobile');
  const acceptLang = isNonEmptyString(acceptLanguage) ? acceptLanguage : DEFAULT_ACCEPT_LANGUAGE;
  const openInaNewTab = JSON.parse(openInaNewTabStr);
  const isServer = !(typeof window != 'undefined' && window.document);
  const { envVarsObject } = useClientEnvVarsStore();
  const propsMarshCode = typeof window !== 'undefined' ? [datalayer?.['prop_marsha_code']] : [];
  const timestamp = useId(); // This id won't change with new component renders.
  const dataLoaded = useRef<boolean>(false);
  const { isEliteUser, memberLevel } =
    typeof window !== 'undefined' ? checkEliteUser(datalayer) : { isEliteUser: false, memberLevel: '' };
  const isRecipeEliteExclusive = isEliteUser ? defaultOffersReceipe === OFFER_RECIPE_ELITE_EXCLUSIVE : false;
  let searchCriteria = `generated-${timestamp}`;
  let cookieSearchCriteria = null;
  let sessionId = null;
  let cookie = null;

  cookie = isServer ? props?.cookie : document?.cookie;
  sessionId = cookie ? getMbopCookie(cookie, MBOX_COOKIE) : null;

  const OffersCardCarouselSkeletonLoader = lazy(() => import('./OffersCardCarouselSkeletonLoader'));

  const checkIsWCMModeResortOffers = () => {
    return getWCMModeFlagValue() && defaultOffersReceipe === RESORT_OFFERS_RECIPE;
  };
  // WEB-70169 - using this to excude the mock file from production build
  const GetCarouselUXLMockJson = async (isAuthorMode: boolean) => {
    if (process.env['NODE_ENV'] === 'development' || isAuthorMode) {
      if (noOfCards === 2 && cardsType === LAYERED_WIDE) {
        const CarouselUXLMockJson = await import('./__mock__/OfferCarouselWideCardUXL.mock.json'); //Load mock model dynamically only for dev mode
        return CarouselUXLMockJson;
      } else if (noOfCards === 3 && cardsType === CARD_SQUARE) {
        const CarouselUXLMockJson = await import('./__mock__/OfferCarouselSquareCardUXL.mock.json'); //Load mock model dynamically only for dev mode
        return CarouselUXLMockJson;
      } else if (noOfCards === 4 && cardsType === CARD_SQUARE) {
        const CarouselUXLMockJson = await import('./__mock__/OfferCarouselUXL.mock.json'); //Load mock model dynamically only for dev mode
        return CarouselUXLMockJson;
      } else if (variation === 'combo') {
        const CarouselUXLMockJson = await import('./__mock__/OfferCarouselComboCardUXL.mock.json'); //Load mock model dynamically only for dev mode
        return CarouselUXLMockJson;
      }
    }
    return {};
  };
  const isWcmModeDisabledFlag = checkIsWCMModeResortOffers();

  //to identify locale and activities are returned respective of that locale
  const workspaceId =
    typeof window !== 'undefined'
      ? window?._satellite?.['_container']?.dataElements?.at_property?.settings?.source?.call()
      : null;
  //to retrieve the latest user profile attributes cached on the edge servers
  const edgeHost = cookie ? getEdgeHost(cookie) : null;

  const resortRequestVariables =
    defaultOffersReceipe === RESORT_OFFERS_RECIPE
      ? {
          region: model?.regionControl,
          resortTypes: model?.activities,
        }
      : {};

  const destinationRequestVariables =
    defaultOffersReceipe === DESTINATION_OFFERS_RECIPE
      ? generateUXLVariablesForDestination(
          latitude,
          longitude,
          stateCode,
          countryCode,
          isStateEnabled,
          isCountryEnabled,
          isPOI
        )
      : {};

  const datalayerPayload =
    typeof window !== 'undefined' && datalayer
      ? targetPayloadRequest(
          {
            isUxl: 'false',
          },
          datalayer
        )
      : {};

  const modifiedDataLayerPayload =
    datalayerPayload?.execute?.mboxes[0]?.parameters &&
    Object.keys(datalayerPayload?.execute?.mboxes[0]?.parameters).length > 0
      ? objectToArray(datalayerPayload?.execute?.mboxes[0]?.parameters)
      : null;

  const targetingCriteria = getTargettingCriteria(
    workspaceId,
    isRecipePropNonStayOffers,
    propsMarshCode,
    resortRequestVariables,
    isRecipeEliteExclusive,
    isEliteUser,
    memberLevel,
    edgeHost,
    modifiedDataLayerPayload
  );

  const offersSearchInputVar = {
    offersSearchInput: {
      queries: [
        {
          id: 'offers',
          values: offerslistjson && offerslistjson.length > 0 ? JSON.parse(JSON.stringify(offerslistjson)) : '[]',
        },
      ],
    },
  };

  // Memoize all variables that affect the query,
  // to prevent re-triggering useQuery if component re-renders.
  const skipQuery =
    useMemo(() => {
      return (!isAuthorMode && dataLoaded.current) || (isAuthorMode && isWcmModeDisabledFlag && dataLoaded.current);
    }, [isAuthorMode, dataLoaded.current]) ||
    (isAuthorMode && !isWcmModeDisabledFlag) ||
    dataLoaded.current ||
    isOfferRecipeNone ||
    (!isAuthorMode && !dataLayerOnLoad) ||
    !isNonEmptyString(acceptLang);
  // Do not execute query unless Mbox-Session-ID is available.
  // Would require a workaround for local dev where there won't be a cookie.
  // || !sessionId;

  cookieSearchCriteria =
    typeof window !== 'undefined'
      ? window?._satellite?.['getVisitorId']()?._fields?.MCMID?.length === 38
        ? window._satellite?.['getVisitorId']()._fields.MCMID
        : searchCriteria
      : searchCriteria;

  if (cookieSearchCriteria) {
    searchCriteria = cookieSearchCriteria;
  }

  const sourceUrl = typeof window !== 'undefined' ? window.location.href : '';
  const requestVariables = generateRequestVariables(
    defaultOffersReceipe,
    OFFER_CAROUSEL_CHANNEL,
    sourceUrl,
    OFFER_CAROUSEL_URL_REFERRER,
    searchCriteria,
    isEliteUser,
    isRecipeEliteExclusive,
    targetingCriteria,
    offersSearchInputVar
  );

  const fallbackRequestVariables = {
    ...offersSearchInputVar,
  };

  const getDestinationOffersQuery = () => {
    if (isPOI || (!isStateEnabled && !isCountryEnabled)) {
      return PhoenixDestinationOffersSearchByGeolocation;
    } else {
      return PhoenixDestinationOffersSearchByArea;
    }
  };
  const queryGQ = isPreview
    ? phoenixOfferPreview
    : defaultOffersReceipe === DESTINATION_OFFERS_RECIPE
    ? getDestinationOffersQuery()
    : phoenixOffersCarousel;

  //Recipe useQuery
  const { loading: isDataLoading } = useQuery(queryGQ, {
    fetchPolicy: isServer ? 'network-only' : 'cache-first',
    errorPolicy: OFFER_CAROUSEL_ERROR_POLICY,
    variables: isPreview
      ? { offerPreviewId: props?.offersData?.offerId }
      : defaultOffersReceipe === DESTINATION_OFFERS_RECIPE
      ? destinationRequestVariables
      : requestVariables,
    onCompleted: async queryData => {
      dataLoaded.current = true;
      if ((queryData && !isAuthorMode) || (queryData && isAuthorMode && isWcmModeDisabledFlag)) {
        if (
          checkNoDataAvailable(
            queryData,
            isPreview,
            defaultOffersReceipe,
            noOfCards,
            isPOI,
            isStateEnabled,
            isCountryEnabled
          )
        ) {
          loadFallbackQuery();
        } else {
          if (defaultOffersReceipe === DESTINATION_OFFERS_RECIPE) {
            const { data } = processUxlData(
              queryData,
              false,
              cardsType,
              model,
              isPOI,
              isStateEnabled,
              isCountryEnabled,
              destinationName
            );
            const updatedMoreCTALink = moreCTALink?.replace('{destinationName}', destinationName);
            setMoreCTALink(updatedMoreCTALink);
            const { count, cardKeys, offerMap } = processOfferCarousel(
              data,
              model,
              isRecipeEliteExclusive,
              envVarsObject,
              cardsType,
              openInaNewTab,
              isPreview
            );
            setCardCount(count);
            setKeys(cardKeys);
            setResultOffer(offerMap);
          } else {
            const { data, concatenatedOfferIds } = processUxlData(
              isPreview ? reformPreviewResponse(queryData) : queryData,
              false,
              cardsType,
              model
            );
            const updatedMoreCTALink = moreCTALink?.replace('{offerids}', concatenatedOfferIds);
            setMoreCTALink(updatedMoreCTALink);
            const { count, cardKeys, offerMap } = processOfferCarousel(
              data,
              model,
              isRecipeEliteExclusive,
              envVarsObject,
              cardsType,
              openInaNewTab,
              isPreview
            );
            setCardCount(count);
            setKeys(cardKeys);
            setResultOffer(offerMap);
          }
        }
      } else if (isAuthorMode && !isWcmModeDisabledFlag) {
        const CarouselMockUXL = await GetCarouselUXLMockJson(isAuthorMode);
        const { data, concatenatedOfferIds } = processUxlData(CarouselMockUXL, false, cardsType, model);
        const updatedMoreCTALink = moreCTALink?.replace('{offerids}', concatenatedOfferIds);
        setMoreCTALink(updatedMoreCTALink);
        const { count, cardKeys, offerMap } = processOfferCarousel(
          data,
          model,
          isRecipeEliteExclusive,
          envVarsObject,
          cardsType,
          openInaNewTab,
          isPreview
        );
        setCardCount(count);
        setKeys(cardKeys);
        setResultOffer(offerMap);
      } else if (!searchCriteria) {
        loadFallbackQuery();
      }
    },

    context: {
      headers: {
        // Only attach headers if they are not null or empty strings,
        // since anything passed on the query will override defaults.
        ...(requestId?.length > 0 && { 'x-request-id': requestId }),
        ...(sessionId && sessionId?.length > 0 && { 'Mbox-Session-ID': sessionId }),
        ...(acceptLang?.length > 0 && { 'accept-language': acceptLang }),
        ...(envVarsObject?.['NEXT_PUBLIC_UAT_AUTH_TOKEN'] &&
          isWcmModeDisabledFlag && { Authorization: envVarsObject?.['NEXT_PUBLIC_UAT_AUTH_TOKEN'] }),
      },
    },
    skip: skipQuery,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fallbackComplete = (fallbackData: any) => {
    if (fallbackData) {
      if (fallbackData?.fallbackOffers?.offerCollection) {
        if (defaultOffersReceipe === DESTINATION_OFFERS_RECIPE) {
          const { data } = processUxlData(
            fallbackData,
            true,
            cardsType,
            model,
            isPOI,
            isStateEnabled,
            isCountryEnabled,
            destinationName
          );
          const updatedMoreCTALink = moreCTALink?.replace('{destinationName}', destinationName);
          setMoreCTALink(updatedMoreCTALink);
          const { count, cardKeys, offerMap } = processOfferCarousel(
            data,
            model,
            isRecipeEliteExclusive,
            envVarsObject,
            cardsType,
            openInaNewTab,
            isPreview
          );
          setCardCount(count);
          setKeys(cardKeys);
          setResultOffer(offerMap);
        } else {
          const { data, concatenatedOfferIds } = processUxlData(fallbackData, true, cardsType, model);
          const updatedMoreCTALink = moreCTALink?.replace('{offerids}', concatenatedOfferIds);
          setMoreCTALink(updatedMoreCTALink);
          const { count, cardKeys, offerMap } = processOfferCarousel(
            data,
            model,
            isRecipeEliteExclusive,
            envVarsObject,
            cardsType,
            openInaNewTab,
            isPreview
          );
          setCardCount(count);
          setKeys(cardKeys);
          setResultOffer(offerMap);
        }
      }
    }
  };

  //fallback query
  const [
    loadFallbackQuery,
    { data: _fallbackResults, error: _fallbackError, called: fallbackCalled, loading: isLazyLoading },
  ] = useLazyQuery(phoenixOffersFallbackOffers, {
    fetchPolicy: isServer ? 'network-only' : 'cache-first',
    errorPolicy: OFFER_CAROUSEL_ERROR_POLICY,
    variables: fallbackRequestVariables,
    context: {
      headers: {
        // Only attach headers if they are not null or empty strings,
        // since anything passed on the query will override mi-apollo-client-utils defaults.
        ...(requestId?.length > 0 && { 'x-request-id': requestId }),
        ...(acceptLang?.length > 0 && { 'accept-language': acceptLang }),
      },
    },
    onCompleted: fallbackComplete,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const reformPreviewResponse = (response: any) => {
    return {
      offersCarousel: [
        {
          __typename: response?.offerPreview['__typename'],
          experienceId: response?.offerPreview['id'],
          offerCollection: [
            {
              catalogId: response?.offerPreview['id'],
              offer: response?.offerPreview?.offer,
            },
          ],
        },
      ],
    };
  };

  useEffect(() => {
    if (datalayer) {
      setDataLayerOnLoad(true);
    }
  }, [datalayer]);
  useEffect(() => {
    function handleResize() {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setIsMobileViewPort(useGetBreakpoint() === 'mobile');
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // CNWEB-2078 - Invoke the fallback query if the offer recipe is set to `none`
  useEffect(() => {
    if (isOfferRecipeNone && !fallbackCalled) {
      loadFallbackQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetOfferCardsInAuthorMode = async () => {
    try {
      const CarouselUXLMock = await GetCarouselUXLMockJson(isAuthorMode);
      const { data, concatenatedOfferIds } = processUxlData(CarouselUXLMock, false, cardsType, model);
      const updatedMoreCTALink = moreCTALink?.replace('{offerids}', concatenatedOfferIds);
      setMoreCTALink(updatedMoreCTALink);
      const { count, cardKeys, offerMap } = processOfferCarousel(
        data,
        model,
        isRecipeEliteExclusive,
        envVarsObject,
        cardsType,
        openInaNewTab,
        isPreview
      );
      setCardCount(count);
      setKeys(cardKeys);
      setResultOffer(offerMap);
    } catch (error) {
      console.error('Error fetching offer cards from mocks:', error);
    }
  };

  //WEB-74989 Load offer cards in author mode
  useEffect(() => {
    const fetchData = async () => {
      if (isAuthorMode && !isWcmModeDisabledFlag) {
        await GetOfferCardsInAuthorMode();
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorMode, isWcmModeDisabledFlag]);

  const allowedComponents = [
    {
      path: '/jcr:content/root/responsivegrid/mi-aem-common-spa/components/content/cardlayered',
      title: 'Card Layered',
    },
    {
      path: '/jcr:content/root/responsivegrid/mi-aem-common-spa/components/content/cardvertical',
      title: 'Card Vertical',
    },
  ];

  const carouselHeaderText = (text: any) => {
    if (defaultOffersReceipe === DESTINATION_OFFERS_RECIPE) {
      const updatedHeaderText = text?.replace('{Destination Name}', city);
      return updatedHeaderText;
    } else {
      return text;
    }
  };
  return (
    <StyledOfferCarouselContainer>
      <div>
        {!isDataLoading && !isLazyLoading ? (
          <div className={clsx(cardCount > 0 ? '' : 'hideCarousel', 'container cardWrapper')}>
            {offerResult?.length > 0 && (
              <CardCarouselContainer
                headerText={
                  !isMobileViewPort ? carouselHeaderText(model?.headerText) : carouselHeaderText(model?.mobileTitle)
                }
                subHeaderText={model?.subHeaderText}
                noOfCards={noOfCards}
                ctaLabel={model?.ctaLabel}
                ctaLink={addSubDirectoryPrefix(makeDomainSpecificContent(moreCTALink))}
                ctaType={model?.ctaType}
                cardCount={cardCount}
                openInaNewTab={openInaNewTab}
                totalNumberOfCards={keys}
                trackingProperties={trackingPropertiesObj(model)}
                styleclass={model?.appliedCssClassNames}
                cqItems={offerResult}
                componentId={model?.componentId}
                pagePath={''}
                itemPath={''}
                wcmMode={model?.wcmMode}
                isAuthorMode={false}
                noOfCardsTablet={NO_OF_CARDS_TABLET}
                allowedComponents={allowedComponents}
                variation={variation ?? VARIATION_INVERSE}
              />
            )}
          </div>
        ) : (
          <div className="container">
            <Suspense fallback={<></>}>
              <OffersCardCarouselSkeletonLoader cardType={cardsType} numberOfCards={noOfCards} />
            </Suspense>
          </div>
        )}
      </div>
    </StyledOfferCarouselContainer>
  );
};

export default CardCarousel;
